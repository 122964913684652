var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("datetime", {
    attrs: {
      "input-class": _vm.cInputClass(),
      type: _vm.type,
      format: _vm.formatDate(),
      "minute-step": _vm.minute_step,
      auto: _vm.auto,
      name: _vm.name,
      utc: _vm.utc,
      "min-datetime": _vm.min_datetime,
      "max-datetime": _vm.max_datetime
    },
    model: {
      value: _vm.datahora,
      callback: function($$v) {
        _vm.datahora = $$v
      },
      expression: "datahora"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }