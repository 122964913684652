var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("toggle-button", {
        ref: "switch",
        attrs: {
          theme: "bootstrap",
          disabled: _vm.disabled,
          labels: { checked: _vm.caption_on, unchecked: _vm.caption_off },
          color: {
            checked: _vm.color_on,
            unchecked: _vm.color_off,
            disabled: "#CCCCCC"
          },
          height: parseFloat(_vm.height),
          width: parseFloat(_vm.width),
          "font-size": parseFloat(_vm.fontsize)
        },
        model: {
          value: _vm.checado,
          callback: function($$v) {
            _vm.checado = $$v
          },
          expression: "checado"
        }
      }),
      _vm._v(" "),
      _c("input", {
        ref: "input_switch",
        staticClass: "hidden",
        attrs: { type: "hidden", name: _vm.name },
        domProps: { value: _vm.checado ? "1" : "0" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }