var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "line" }, [
    _vm.line.ele == "h2"
      ? _c("h2", [_vm._v(" " + _vm._s(_vm.line.caption) + " ")])
      : _vm._e(),
    _vm._v(" "),
    _vm.line.ele == "h4"
      ? _c("h4", [_vm._v(" " + _vm._s(_vm.line.caption) + " ")])
      : _vm._e(),
    _vm._v(" "),
    _vm.line.ele == "title"
      ? _c("h4", { staticClass: "title" }, [
          _vm._v(" " + _vm._s(_vm.line.caption) + " ")
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.line.ele == "small"
      ? _c("h4", { staticClass: "small" }, [
          _vm._v(" " + _vm._s(_vm.line.caption) + " ")
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }