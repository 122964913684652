var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input-group date" },
    [
      _c("date-picker", {
        ref: "dtpick",
        class: _vm.cInputClass(),
        attrs: {
          config: _vm.configDatePicker(),
          name: _vm.name,
          id: _vm.id,
          value: _vm.data,
          required: _vm.required,
          readonly: _vm.readonly
        },
        on: {
          "dp-change": _vm.updateDate,
          "dp-show": _vm.onShowHandler,
          "dp-update": _vm.onUpdateHandler
        },
        model: {
          value: _vm.data,
          callback: function($$v) {
            _vm.data = $$v
          },
          expression: "data"
        }
      }),
      _vm._v(" "),
      !_vm.required && _vm.data != null && !_vm.inline
        ? _c("div", { staticClass: "input-group-addon" }, [
            _c("span", {
              staticClass: "fa fa-trash",
              on: {
                click: function($event) {
                  return _vm.clearDate()
                }
              }
            })
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }