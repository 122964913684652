// cmp-datetime.vue
<template>
  <datetime v-model="datahora"
            :input-class="cInputClass()"
            :type="type"
            :format="formatDate()"
            :minute-step="minute_step"
            :auto="auto"
            :name="name"
            :utc="utc"
            :min-datetime="min_datetime"
            :max-datetime="max_datetime"

  ></datetime>
</template>

<script>

  import numberMask from '../mixins/numberMask.vue'
  import inputUtils from '../mixins/inputUtils.vue'

  // Registrado na modal_app
  import { Datetime } from 'vue-datetime';

  import { Settings } from 'luxon'
  Settings.defaultLocale = 'pt-br'

  export default {
    name: 'cmp-datetime',
    components: {
      'datetime'        : Datetime,
    },
    mixins: [numberMask, inputUtils],
    props: {
      input_class: {
        type: String,
        default: () => {
          return 'form-control string '
        }
      },
      required: {
        type: Boolean,
        default: () => {
          return false
        }
      },
      value: {
        type: String,
        default: () => {
          // return moment(Date.now()).format("DD/MM/YYYY");
          return moment(Date.now(), moment().format('LLLL'));
        }
      },
      type: {
        type: String,
        default: () => {
          return 'date'
        }
      },
      minute_step: {
        type: Number,
        default: () => {
          return 10
        }
      },
      auto: {
        type: Boolean,
        default: () => {
          return true
        }
      },
      name: {
        type: String,
        default: () => {
          return ''
        }
      },
      utc: {
        type: String,
        default: () => {
          return 'UTC-3'
        }
      },
      min_datetime: {
        type: String,
        default: () => {
          return ''
        }
      },
      max_datetime: {
        type: String,
        default: () => {
          return ''
        }
      }
    },

    data: function () {
      return {
        datahora: null,

        nadamais: null
      }
    },
    mounted: function () {
      // Settings.defaultLocale = 'pt-Br'
      moment.locale('pt-br');
      // this.datahora = moment(this.value).toDate();
      // this.datahora = moment(this.value).toISOString()
      this.datahora = moment(this.value, moment().format('LLLL'))

    },
    methods: {
      cInputClass: function() {
        return this.input_class + (this.required ? ' required' : '');
      },
      formatDate: function () {
        if (this.type == 'date') {
          return {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
          };
        } else {
          return {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: '2-digit',
            hour12: false,
          };
        }
      }
    },
    computed: {
    }
  }
</script>

<style>
</style>



