// cmp-v-select.vue
<template>
  <div>

    <multiselect
      :multiple="multiple"
      :closeOnSelect="! multiple"
      :allowEmpty="multiple"
      :disabled="disabled"
      track-by="value"
      label="label"
      selectedLabel="Selecionado"
      selectLabel=""
      :deselectLabel="(multiple ? 'Remover?' : '')"
      :placeholder="placeholder"
      :optionHeight="32"
      :options="options"
      v-model="selectedObj"
      :value="selectedValue2Select"
      @input="updateSelected"
      :group-values="group_values"
      :group-label="group_label"
      :group-select="(group_select == 'true')"
    >
    </multiselect>

    <div v-if="multiple">
      <input :name="name + '[]'"
             type="hidden" value="">

      <select multiple="multiple"
              :name="name + '[]'"
              class="hidden">
        <option v-for="(val, seq) in selectedValue2Hidden"
                class="hidden"
                :key="'opt_' + name + seq"
                selected="selected"
                :value="val">
        </option>
      </select>
    </div>
    <input v-else
           class="hidden"
           :id="input_hidden_id"
           :key="refreshInc"
           :name="modelInputName()"
           v-model="selectedValue2Hidden"
    >
<!--
           :value="selectedValue2Hidden"
 -->
  </div>
</template>

<script>

  import Multiselect from 'vue-multiselect'
  import inputUtils     from 'packs/components/mixins/inputUtils.vue'


  export default {
    name: 'cmp-v-select',
    components: {
      'multiselect': Multiselect
    },
    mixins: [inputUtils],
    props: {
      input_class: {
        type: String,
        default: () => {
          return 'form-control string '
        }
      },
      required: {
        type: Boolean,
        default: () => {
          return false
        }
      },
      multiple: {
        type: Boolean,
        default: () => {
          return false
        }
      },
      disabled: {
        type: Boolean,
        default: () => {
          return false
        }
      },
      taggable: {
        type: Boolean,
        default: () => {
          return false
        }
      },
      value: {
        default: () => {
          return null
        }
      },
      options: {
        type: Array,
        default: () => {
          return []
        }
      },
      returnObj: {
        type: Boolean,
        default: () => {
          return false
        }
      },
      name: {
        type: String,
        default: () => {
          return ''
        }
      },
      model_name: {
        type: String,
        default: () => {
          return ''
        }
      },
      input_name: {
        type: String,
        default: () => {
          return ''
        }
      },
      input_hidden_id: {
        type: String,
        default: () => {
          return null
        }
      },
      placeholder: {
        type: String,
        default: () => {
          return ''
        }
      },
      index: {
        default: () => {
          return null
        }
      },
      onSelected: {
        type: Function,
        default: () => {
          return null
        }
      },
      group_values: {
        default: () => {
          return null
        }
      },
      group_label: {
        default: () => {
          return null
        }
      },
      group_select: {
        type: String,
        default: () => {
          return 'false'
        }
      }
    },

    data: function () {
      return {
        // selected: {
        //   obj: null,
        //   value: null
        // },
        selectedObj:     null,
        selected2hidden: null,
        hidden: {
          value: null
        },
        refreshInc: 10
      }
    },
    mounted: function () {
      var that = this
      var index = -1
      var arrValues = []

      if ((this.value !== null) && (typeof(this.value) !== 'undefined') && (this.value !== '')) {
        if (Array.isArray(this.value)) {
          arrValues = this.value
        } else {
          arrValues.push(this.value)
        }
      }

      // Quand ousamos Grupos e Não Multiple
      if (this.group_values) {
        that.$set(that, 'selectedObj',     [that.value])
        that.$set(that, 'selected2hidden', [that.value.value])
        return
      }

      if (this.multiple) {
        this.$set(this, 'selectedObj', [])
        this.$set(this, 'selected2hidden', [])
      }

      arrValues.forEach((val, index) => {
        var value = null
        if (typeof val === 'object') {
          value = val.value
        } else {
          value = val
        }

        index = that.options.findIndex(opt => opt.value === value)
        if (index > -1) {
          if (that.multiple) {
            // that.$set(that.selected.obj, index, that.options[index])
            // that.$set(that.selected.value, index, that.options[index].value)

            that.selectedObj.push(that.options[index]);
            that.selected2hidden.push(that.options[index].value);
          } else {
            that.$set(that, 'selectedObj',     that.options[index])
            that.$set(that, 'selected2hidden', that.options[index].value)
          }
        }
      })
    },
    methods: {
      cInputClass: function() {
        return this.input_class + (this.required ? ' required' : '');
      },
      modelInputName: function(seq) {
        var strAttrib = this.name;
        if (typeof seq !== 'undefined') {
          strAttrib += '[]'
          strAttrib = strAttrib.replace('[]', '[' + seq.toString() + ']');
        }
        return strAttrib ;
      },
      updateSelected: function(selObj) {
        var selValue = null
        if (selObj) {
          if (this.multiple) {
            selValue = []
            for (var i = 0, len = selObj.length; i < len; i++) {
              selValue.push(selObj[i].value)
            }
          } else {
            selValue = selObj.value
          }
        }
        this.$set(this, 'selected2hidden', selValue)
        this.$set(this, 'refreshInc', this.refreshInc + 1);
        if (this.returnObj) {
          this.$emit('input', selObj);
        } else {
          this.$emit('input', selValue);
        }

        if (this.onSelected) {
          this.onSelected(this.index, selObj)
        }
      }
    },
    computed: {
      selectedValue2Hidden: function() {
        // if (this.selected2hidden ||  ) {return this.selected2hidden }
        return this.selected2hidden;
      },
      selectedValue2Select: function() {
        if (this.selectedObj) {return this.selectedObj }
      }
    }
  }
</script>

<style>
.multiselect__option--selected.multiselect__option--highlight {
    background: #cabcbc !important;
    color: #fff;
}
.multiselect__spinner {
  position: absolute;
  right: 1px;
  top: 1px;
  width: 48px;
  height: 35px;
  background: #fff;
  display: block;
}

.multiselect__spinner:before,
.multiselect__spinner:after {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border-color: #41b883 transparent transparent;
  border-style: solid;
  border-width: 2px;
  box-shadow: 0 0 0 1px transparent;
}

.multiselect__spinner:before {
  animation: spinning 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
  animation-iteration-count: infinite;
}

.multiselect__spinner:after {
  animation: spinning 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
  animation-iteration-count: infinite;
}

.multiselect__loading-enter-active,
.multiselect__loading-leave-active {
  transition: opacity 0.4s ease-in-out;
  opacity: 1;
}

.multiselect__loading-enter,
.multiselect__loading-leave-active {
  opacity: 0;
}

.multiselect,
.multiselect__input,
.multiselect__single {
  font-family: inherit;
  font-size: 13px;
  touch-action: manipulation;
}

.multiselect {
  box-sizing: content-box;
  display: block;
  position: relative;
  width: 100%;
  min-height: 40px;
  text-align: left;
  color: #35495e;
}

.multiselect * {
  box-sizing: border-box;
}

.multiselect:focus {
  outline: none;
}

.multiselect--disabled {
  background: #ededed;
  pointer-events: none;
  opacity: 0.6;
}

.multiselect--active {
  z-index: 50;
}

.multiselect--active:not(.multiselect--above) .multiselect__current,
.multiselect--active:not(.multiselect--above) .multiselect__input,
.multiselect--active:not(.multiselect--above) .multiselect__tags {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.multiselect--active .multiselect__select {
  transform: rotateZ(180deg);
}

.multiselect--above.multiselect--active .multiselect__current,
.multiselect--above.multiselect--active .multiselect__input,
.multiselect--above.multiselect--active .multiselect__tags {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.multiselect__input,
.multiselect__single {
  position: relative;
  display: inline-block;
  min-height: 20px;
  line-height: 20px;
  border: none;
  border-radius: 5px;
  background: #fff;
  padding: 0 0 0 5px;
  width: calc(100%);
  transition: border 0.1s ease;
  box-sizing: border-box;
  margin-bottom: 8px;
  vertical-align: top;
}

.multiselect__input::placeholder {
  color: #35495e;
}

.multiselect__tag ~ .multiselect__input,
.multiselect__tag ~ .multiselect__single {
  width: auto;
}

.multiselect__input:hover,
.multiselect__single:hover {
  border-color: #cfcfcf;
}

.multiselect__input:focus,
.multiselect__single:focus {
  border-color: #a8a8a8;
  outline: none;
}

.multiselect__single {
  padding-left: 5px;
  margin-bottom: 8px;
}

.multiselect__tags-wrap {
  display: inline;
}

.multiselect__tags {
  min-height: 40px;
  display: block;
  padding: 8px 40px 0 8px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 14px;
}

.multiselect__tag {
  position: relative;
  display: inline-block;
  padding: 4px 26px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #41b883;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}

.multiselect__tag-icon {
  cursor: pointer;
  margin-left: 7px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  font-weight: 400;
  font-style: initial;
  width: 22px;
  text-align: center;
  line-height: 22px;
  transition: all 0.2s ease;
  border-radius: 5px;
}

.multiselect__tag-icon:after {
  content: "×";
  color: #266d4d;
  font-size: 14px;
}

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: #369a6e;
}

.multiselect__tag-icon:focus:after,
.multiselect__tag-icon:hover:after {
  color: white;
}

.multiselect__current {
  line-height: 16px;
  min-height: 40px;
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  padding: 8px 12px 0;
  padding-right: 30px;
  white-space: nowrap;
  margin: 0;
  text-decoration: none;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  cursor: pointer;
}

.multiselect__select {
  line-height: 16px;
  display: block;
  position: absolute;
  box-sizing: border-box;
  width: 40px;
  height: 38px;
  right: 1px;
  top: 1px;
  padding: 4px 8px;
  margin: 0;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.multiselect__select:before {
  position: relative;
  right: 0;
  top: 65%;
  color: #999;
  margin-top: 4px;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: #999999 transparent transparent transparent;
  content: "";
}

.multiselect__placeholder {
  color: #adadad;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
}

.multiselect--active .multiselect__placeholder {
  display: none;
}

.multiselect__content-wrapper {
  position: absolute;
  display: block;
  background: #fff;
  width: 100%;
  max-height: 240px;
  overflow: auto;
  border: 1px solid #e8e8e8;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 50;
  -webkit-overflow-scrolling: touch;
}

.multiselect__content {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin: 0;
  min-width: 100%;
  vertical-align: top;
}

.multiselect--above .multiselect__content-wrapper {
  bottom: 100%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: none;
  border-top: 1px solid #e8e8e8;
}

.multiselect__content::webkit-scrollbar {
  display: none;
}

.multiselect__element {
  display: block;
}

.multiselect__option {
  display: block;
  padding: 12px;
  min-height: 40px;
  line-height: 16px;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
}

.multiselect__option:after {
  top: 0;
  right: 0;
  position: absolute;
  line-height: 40px;
  padding-right: 12px;
  padding-left: 20px;
  font-size: 13px;
}

.multiselect__option--highlight {
  background: #41b883;
  outline: none;
  color: white;
}

.multiselect__option--highlight:after {
  content: attr(data-select);
  background: #41b883;
  color: white;
}

.multiselect__option--selected {
  background: #f3f3f3;
  color: #35495e;
  font-weight: bold;
}

.multiselect__option--selected:after {
  content: attr(data-selected);
  color: silver;
}

.multiselect__option--selected.multiselect__option--highlight {
  background: #ff6a6a;
  color: #fff;
}

.multiselect__option--selected.multiselect__option--highlight:after {
  background: #ff6a6a;
  content: attr(data-deselect);
  color: #fff;
}

.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select {
  background: #ededed;
  color: #a6a6a6;
}

.multiselect__option--disabled {
  background: #ededed !important;
  color: #a6a6a6 !important;
  cursor: text;
  pointer-events: none;
}

.multiselect__option--group {
  background: #ededed;
  color: #35495e;
}

.multiselect__option--group.multiselect__option--highlight {
  background: #35495e;
  color: #fff;
}

.multiselect__option--group.multiselect__option--highlight:after {
  background: #35495e;
}

.multiselect__option--disabled.multiselect__option--highlight {
  background: #dedede;
}

.multiselect__option--group-selected.multiselect__option--highlight {
  background: #ff6a6a;
  color: #fff;
}

.multiselect__option--group-selected.multiselect__option--highlight:after {
  background: #ff6a6a;
  content: attr(data-deselect);
  color: #fff;
}

.multiselect-enter-active,
.multiselect-leave-active {
  transition: all 0.15s ease;
}

.multiselect-enter,
.multiselect-leave-active {
  opacity: 0;
}

.multiselect__strong {
  margin-bottom: 8px;
  line-height: 20px;
  display: inline-block;
  vertical-align: top;
}

*[dir="rtl"] .multiselect {
  text-align: right;
}

*[dir="rtl"] .multiselect__select {
  right: auto;
  left: 1px;
}

*[dir="rtl"] .multiselect__tags {
  padding: 8px 8px 0px 40px;
}

*[dir="rtl"] .multiselect__content {
  text-align: right;
}

*[dir="rtl"] .multiselect__option:after {
  right: auto;
  left: 0;
}

*[dir="rtl"] .multiselect__clear {
  right: auto;
  left: 12px;
}

*[dir="rtl"] .multiselect__spinner {
  right: auto;
  left: 1px;
}

</style>
