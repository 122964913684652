// numberMask.vue

<script>

  import createNumberMask from 'text-mask-addons/dist/createNumberMask'

  export default {
    methods: {
      numberMask: function(int_len, dec_len) {
        int_len = int_len != 0 ? int_len : null ;
        int_len = typeof int_len !== 'undefined' ? int_len : null ;
        dec_len = typeof dec_len !== 'undefined' ? dec_len : 0    ;
        var nm = createNumberMask({
          prefix: '',
          thousandsSeparatorSymbol: '.',
          decimalSymbol: ',',
          allowDecimal: (dec_len > 0),
          integerLimit: int_len,
          decimalLimit: dec_len
        });
        return nm ;
      },
      strBr2Number: function(str_number_br) {
        if (typeof str_number_br == 'number') { return str_number_br;}
        if ((typeof str_number_br !== 'undefined')  && (str_number_br !== null)) {
          var resp = '';
          resp = str_number_br.replace(".", "");
          resp = resp.replace(",", ".");
          if (resp === "") {resp = 0} ;
          return parseFloat(resp);
        } else {
          return 0;
        }
      },
      str2Number: function(str_number) {
        if (typeof str_number == 'number') { return str_number;}
        if ((typeof str_number !== 'undefined')
             && (str_number !== null)
             && (str_number !== '')) {
          str_number = str_number.replace(",", "");
          return parseFloat(str_number);
        } else {
          return 0;
        }
      },
      number2strBr: function(value, decimals) {
        decimals = typeof decimals !== 'undefined' ? decimals : 0 ;
        if ((typeof value !== 'undefined') && (value !== null)) {
          value = typeof value == 'string' ? parseFloat(value) : value
          return value.toLocaleString("pt-BR", {
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals });
        } else {
          return '0'
        }
      },
      number2Real: function(value) {
        return value.toLocaleString("pt-BR", { style: 'currency', currency: 'BRL' });
      },
      number2str: function(value, decimals) {
        value = typeof value == 'string' ? parseFloat(value) : value ;
        // var resp = value.toString() ;
        // return resp;
        decimals = typeof decimals !== 'undefined' ? decimals : 0 ;
        if ((typeof value !== 'undefined') && (value !== null)) {
          return (Math.trunc(value * 1000000000000) / 1000000000000).toFixed(decimals);
        } else {
          return '0'
        }
      },
      // Arquivo morto
      preventNonNumeric: function (evt) {
        if ((evt.which < 48 || evt.which > 57) && (evt.which != 44) && (evt.which != 46))
          { evt.preventDefault(); }
      }


    }
  }
</script>

