// cmp-datepicker.vue
<template>
  <div class="input-group date">
    <date-picker
        :config="configDatePicker()"
        :class="cInputClass()"
        :name="name"
        :id="id"
        :value="data"
        v-model="data"
        @dp-change="updateDate"
        @dp-show="onShowHandler"
        @dp-update="onUpdateHandler"
        ref="dtpick"
        :required="required"
        :readonly="readonly">
    </date-picker>
    <div v-if="(! required) && (data != null) && (! inline)"
         class="input-group-addon">
      <span
            @click="clearDate()"
            class="fa fa-trash">
      </span>
    </div>
  </div>
</template>

<script>

  import BtDatePicker from 'vue-bootstrap-datetimepicker';

  export default {
    name: 'cmp-datepicker',
    // mixins: [numberMask, inputUtils],
    components: {
      'date-picker'           : BtDatePicker
    },
    props: {
      input_class: {
        type: String,
        default: () => {
          return 'form-control date v-datepicker'
        }
      },
      required: {
        type: Boolean,
        default: () => {
          return false
        }
      },
      value: {
        type: String,
        default: () => {
          return ''
        }
      },
      readonly: {
        type: Boolean,
        default: () => {
          return false
        }
      },
      name: {
        type: String,
        default: () => {
          return ''
        }
      },
      id: {
        type: String,
        default: () => {
          return ''
        }
      },
      utc: {
        type: String,
        default: () => {
          return 'UTC-3'
        }
      },
      inline: {
        type: Boolean,
        default: () => {
          return false
        }
      },
      widgetParent: {
        type: Object,
        default: () => {
          return null
        }
      },
      min_date: {
        type: String,
        default: () => {
          return ''
        }
      },
      max_date: {
        type: String,
        default: () => {
          return ''
        }
      },
      onChangeHandler: {
        type: Function,
        default: () => {
          return null
        }
      },
      onShowHandler: {
        type: Function,
        default: () => {
          return null
        }
      },
      onUpdateHandler: {
        type: Function,
        default: () => {
          return null
        }
      }
    },

    data: function () {
      return {
        data: null,

        inputFormat: 'YYYY/MM/DD',
        // inputFormat: 'DD/MM/YYYY',

        nadamais: null
      }
    },
    mounted: function () {
      moment.locale('pt-br');
      var inValue = this.value

      if (inValue && (inValue != '')) {
        var sep = null
        var first = null
        if (inValue.includes('/')) {sep = '/'}
        if (inValue.includes('-')) {sep = '-'}
        if ((sep) && (inValue.substring(4,5) == sep)) {first = 'Y'}
        if ((sep) && (inValue.substring(2,3) == sep)) {first = 'D'}
        if (sep && (first == 'Y')) {
          this.inputFormat = 'YYYY' + sep + 'MM' + sep + 'DD';
        }
        if (sep && (first == 'D')) {
          this.inputFormat = 'DD' + sep + 'MM' + sep + 'YYYY';
        }

        this.data = this.server2FrontDateFormat(this.value)
      }
      var that = this;
      $(this.$refs.dtpick.$el).on('dp.change', function(e) {
        that.updateDate(e);
      })

    },
    methods: {
      updateDate: function(dateEvent) {
        if (this.onChangeHandler) {
          this.onChangeHandler()
        }
        var data = dateEvent.date
        if (data) {
          this.$emit('input', this.server2FrontDateFormat(data))
        } else { this.$emit('input', null) }
      },
      clearDate: function() {
        this.$set(this, 'data', null)
        return false ;
      },


      configDatePicker: function() {
        var res = {
          format: 'DD/MM/YYYY',
          inline: this.inline,
          locale: 'pt-br',
          // locale: 'de',
          widgetParent: this.widgetParent
        }
        if (this.min_date) {
          res.minDate = this.min_date
        }
        return res;
      },
      cInputClass: function() {
        return this.input_class + (this.required ? ' required' : '');
      },
      server2FrontDateFormat: function(date) {
        return moment(date, this.inputFormat).format('DD/MM/YYYY');
      },
      Front2ServerDateFormat: function(date) {
        return moment(date, 'DD/MM/YYYY').format(this.inputFormat);
      }
    },
    computed: {
    }
  }
</script>
