
import AutoNumeric from 'autonumeric';

import "vue-multiselect/dist/vue-multiselect.min.css"

import CmpAutonumeric from 'packs/components/comp/cmp-autonumeric.vue';
import CmpCheckBox    from 'packs/components/comp/cmp-checkbox.vue';
import CmpDatePicker  from 'packs/components/comp/cmp-datepicker.vue';
import CmpDateTime    from 'packs/components/comp/cmp-datetime.vue';
import CmpDropZone    from 'packs/components/comp/cmp-dropzone.vue';
import CmpVSelect     from 'packs/components/comp/cmp-v-select.vue';
import FormCab        from 'packs/components/form/form-cab.vue';

import { Datetime } from 'vue-datetime';

// https://br.vuejs.org/v2/guide/components-dynamic-async.html#Componentes-Assincronos
// A função `import` retorna uma Promise.
// Vue.component( 'cmp-autonumeric', () => import('./components/comp/cmp-autonumeric.vue'))


const ModalLightVm = {
  name: 'ModalLightApp',
  components: {
      'cmp-autonumeric' : CmpAutonumeric,
      'cmp-checkbox'    : CmpCheckBox,
      'cmp-datepicker'  : CmpDatePicker,
      'cmp-datetime'    : CmpDateTime,
      'cmp-dropzone'    : CmpDropZone,
      'cmp-v-select'    : CmpVSelect,
      'form-cab'        : FormCab,
      'datetime'        : Datetime
  },
  data: {
    name: 'name_ModalLightApp',
    responsavel: null,
    selected_papel_id: 9,
    form: {}
  },
  created: function() {
  },
  methods: {
  }
}

export default ModalLightVm ;
