var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("multiselect", {
        attrs: {
          multiple: _vm.multiple,
          closeOnSelect: !_vm.multiple,
          allowEmpty: _vm.multiple,
          disabled: _vm.disabled,
          "track-by": "value",
          label: "label",
          selectedLabel: "Selecionado",
          selectLabel: "",
          deselectLabel: _vm.multiple ? "Remover?" : "",
          placeholder: _vm.placeholder,
          optionHeight: 32,
          options: _vm.options,
          value: _vm.selectedValue2Select,
          "group-values": _vm.group_values,
          "group-label": _vm.group_label,
          "group-select": _vm.group_select == "true"
        },
        on: { input: _vm.updateSelected },
        model: {
          value: _vm.selectedObj,
          callback: function($$v) {
            _vm.selectedObj = $$v
          },
          expression: "selectedObj"
        }
      }),
      _vm._v(" "),
      _vm.multiple
        ? _c("div", [
            _c("input", {
              attrs: { name: _vm.name + "[]", type: "hidden", value: "" }
            }),
            _vm._v(" "),
            _c(
              "select",
              {
                staticClass: "hidden",
                attrs: { multiple: "multiple", name: _vm.name + "[]" }
              },
              _vm._l(_vm.selectedValue2Hidden, function(val, seq) {
                return _c("option", {
                  key: "opt_" + _vm.name + seq,
                  staticClass: "hidden",
                  attrs: { selected: "selected" },
                  domProps: { value: val }
                })
              }),
              0
            )
          ])
        : _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selectedValue2Hidden,
                expression: "selectedValue2Hidden"
              }
            ],
            key: _vm.refreshInc,
            staticClass: "hidden",
            attrs: { id: _vm.input_hidden_id, name: _vm.modelInputName() },
            domProps: { value: _vm.selectedValue2Hidden },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.selectedValue2Hidden = $event.target.value
              }
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }