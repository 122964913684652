var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-dropzone", {
        ref: "myVueDropzone",
        attrs: { id: "dropzone", options: _vm.dzOptions() },
        on: {
          "vdropzone-sending": _vm.addParams,
          "vdropzone-success-multiple": _vm.multiSucess,
          "vdropzone-success": _vm.oneSucess,
          "vdropzone-error": _vm.errorInspect
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }