// form-cab-line.vue
<template>
  <div class="line">
    <h2 v-if='line.ele == "h2"'> {{ line.caption }} </h2>
    <h4 v-if='line.ele == "h4"'> {{ line.caption }} </h4>
    <h4 class="title" v-if='line.ele == "title"'> {{ line.caption }} </h4>
    <h4 class="small" v-if='line.ele == "small"'> {{ line.caption }} </h4>
  </div>
</template>

<script>

  export default {
    props: ['line']
  }
</script>

