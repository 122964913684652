// cmp-dropzone.vue
<template>
  <div>
    <vue-dropzone ref="myVueDropzone"
                  id="dropzone"
                  :options="dzOptions()"
                  v-on:vdropzone-sending="addParams"
                  v-on:vdropzone-success-multiple="multiSucess"
                  v-on:vdropzone-success="oneSucess"
                  v-on:vdropzone-error="errorInspect">
    </vue-dropzone>
  </div>

</template>

<script>

  import vue2Dropzone from 'vue2-dropzone' ;

  export default {
    name: 'cmp-drop-zone',
    components: {
      'vue-dropzone': vue2Dropzone
    },
    props: {
      input_class: {
        type: String,
        default: () => {
          return 'form-control string '
        }
      },
      required: {
        type: Boolean,
        default: () => {
          return false
        }
      },
      token: {
        type: String,
        default: () => {
          return ''
        }
      },
      url: {
        type: String,
        default: () => {
          return '/teste'
        }
      },
      method: {
        type: String,
        default: () => {
          return 'post'
        }
      },
      as: {
        type: String,
        default: () => {
          return 'file'
        }
      },
      multiple: {
        type: Boolean,
        default: () => {
          return false
        }
      },
      taggable: {
        type: Boolean,
        default: () => {
          return false
        }
      },
      value: {
        type: Number,
        default: () => {
          return null
        }
      },
      initmsg: {
        type: String,
        default: () => {
          return 'Clique ou arraste e solte os arquivos aqui'
        }
      },
      options: {
        type: Object,
        default: () => {
          return {}
        }
      },
      name: {
        type: String,
        default: () => {
          return ''
        }
      },
      model_name: {
        type: String,
        default: () => {
          return ''
        }
      },
      input_name: {
        type: String,
        default: () => {
          return ''
        }
      }
    },

    data: function () {
      return {
        selected: {
          obj: null,
          value: null
        },
        hidden: {
          value: null
        }
      }
    },
    mounted: function () {
      var that = this
      var index = -1
      if (this.value && (this.value != '')) {
        index = this.options.findIndex(opt => opt.value === that.value)
        if (index > -1) {
          this.$set(this.selected, 'obj', this.options[index])
        }
      }
    },
    methods: {
      dzOptions: function() {
        var opts = {
          url: this.url,
          method: this.method,
          paramName: this.as,
          maxFilesize: 5,
          timeout: null,
          dictDefaultMessage: this.initmsg,
          dictFileTooBig: 'Documento de {{filesize}} excede o limite de {{maxFilesize}}'
        }
        return opts
      },
      addParams: function (file, xhr, formData) {
        // formData.append( 'arquivo',   this.arquivo2Create() );
        formData.append('authenticity_token', this.token);
      },
      multiSucess: function(par1, par2) {
        window.vm.alert('success', 'Documento carregado com sucesso!') ;
        window.vm.refresh(this.options.target_refresh)
      },
      oneSucess: function(par1, par2) {
        window.vm.alert('success', 'Documento carregado com sucesso!') ;
        window.vm.refresh(this.options.target_refresh)
      },
      errorInspect: function (pa1, pa2, pa3) {
        // body...
      },

      cInputClass: function() {
        return this.input_class + (this.required ? ' required' : '');
      },
      modelInputName: function() {
        return this.name;
      },
      updateSelected: function(selObj) {
        this.$emit('input', selObj.value);
        this.$set(this.hidden, 'value', selObj.value)
      }
    },
    computed: {
    }
  }
</script>

<style>
</style>



