// form-cab.vue
<template>
  <div class="header">
    <form-cab-line v-for="(line, index) in aLinhas()"
     :key="index"
     :line= 'line'>

   </form-cab-line>
  </div>
</template>

<script>
  import FormCabLine from './form-cab-line.vue'

  export default {
    props: ['header'],
    components: {
      'form-cab-line': FormCabLine
    },
    methods: {
      aLinhas: function() {
        var keys = Object.keys(this.header);
        var a_header = [] ;
        keys.forEach((k, index) => {
          a_header.push({ele: k, caption: this.header[k]})
        })
        return a_header ;
      }
    }
  }
</script>

