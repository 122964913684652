// cmp-autonumeric.vue
<template>
  <input type="text"
         ref='input'
         autocomplete="off"
         :class="input_class"
         :name="name"
         :id="id"
         :value="value_to_f">
</template>

<script>

  import numberMask from 'packs/components/mixins/numberMask.vue'
  import inputUtils from 'packs/components/mixins/inputUtils.vue'
  // import AutoNumeric from 'autonumeric';

  export default {
    name: 'cmp-autonumeric',
    mixins: [numberMask, inputUtils],
    props: {
      input_class: {
        type: String,
        default: () => {
          return 'form-control number v-autonumeric'
        }
      },
      required: {
        type: Boolean,
        default: () => {
          return false
        }
      },
      value: {
        type: String,
        default: () => {
          return '0'
        }
      },
      decimal_places: {
        default: () => {
          return 2
        }
      },
      readonly: {
        type: Boolean,
        default: () => {
          return false
        }
      },
      name: {
        type: String,
        default: () => {
          return ''
        }
      },
      id: {
        type: String,
        default: () => {
          return ''
        }
      },
      min: {
        type: Number,
        default: () => {
          return null
        }
      },
      max: {
        type: Number,
        default: () => {
          return null
        }
      }
    },
    data: function () {
      return {
        thisIn: null,
        thisAn: null,

        hidden: {
          value: null
        }
      }
    },
    mounted: function () {
      this.thisIn = $(this.$refs.input)
      var anOpts =   this.initAnOptions()
//       debugger
      this.thisAn = new AutoNumeric(this.$refs.input, this.value_to_f, anOpts);

      var that = this;
      this.thisIn.on('change', function(e) {
        that.updateValue(e);
      })

      var parentForm = this.thisAn.form()
      $(parentForm).on('submit', function() {
        that.removeLocalizedFormat()
        return true
      })

      // this.formatValue();
    },
    methods: {
      removeLocalizedFormat: function() {
        this.thisAn.unformat()
      },
      initAnOptions: function() {
//         debugger

        var auOpts = {
          decimalCharacter: ',',
          digitGroupSeparator: '.',
          decimalCharacterAlternative: '.',
          decimalPlaces: Number(this.decimal_places)
        }
        if (this.min)      {auOpts.minimumValue = this.min}
        if (this.max)      {auOpts.maximumValue = this.max}
        if (this.readOnly) {auOpts.readOnly = true}

        return auOpts

      },
      updateValue: function (event) {
        var n_value = this.thisAn.getNumber()
        this.$emit('input', n_value)

        // console.log(this.thisAn.formNumericString())
      // },
      // formatValue: function () {
      //   var dp = Number(this.decimal_places)
      //   this.$refs.input.value = this.number2strBr(this.value,dp);
      }
    },
    computed: {
      value_to_f: function() {
        return parseFloat(this.value)
      }
    }
  }
</script>

