var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("input", {
    ref: "input",
    class: _vm.input_class,
    attrs: { type: "text", autocomplete: "off", name: _vm.name, id: _vm.id },
    domProps: { value: _vm.value_to_f }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }