// inputUtils.vue
<script>
  export default {
    methods: {
      controlName: function(att_name, seq) {
        var strAttrib = att_name;
        if (typeof seq !== 'undefined') {
          strAttrib = strAttrib.replace('[]', '[' + seq.toString() + ']');
        }
        return this.model_name + strAttrib ;
      },
      controlId: function(att_name, seq) {
        var strAttrib = att_name;
        if (typeof seq !== 'undefined') {
          strAttrib = strAttrib + seq.toString();
        }
        return this.model_name + strAttrib ;
      }
    }
  }
</script>
